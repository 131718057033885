import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Config from './Config';
import { useFlags } from 'flagsmith/react';

const OpportunityContext = createContext({
  opportunities: {},
  setOpportunities: () => {},
});

const OpportunityContextProvider = ({ children }) => {
  const [opportunities, setOpportunities] = useState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['opportunity_linking']);

  useEffect(() => {
    if (isAuthenticated && flags.opportunity_linking.enabled) {
      async function getOpps() {
        axios
          .get(Config.resourceServer.demoAPI + '/opportunities', {
            headers: {
              Authorization: 'Bearer ' + (await getAccessTokenSilently()),
            },
          })
          .then((response) => {
            setOpportunities(response.data);
          })
          .catch((error) => {
            console.log(error);
            setOpportunities([]);
          });
      }
      getOpps();
    } else {
      setOpportunities([]);
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    setOpportunities,
    flags.opportunity_linking.enabled,
  ]);

  return (
    <OpportunityContext.Provider value={{ opportunities, setOpportunities }}>
      {children}
    </OpportunityContext.Provider>
  );
};
export const useOpportunityContext = () => useContext(OpportunityContext);
export default OpportunityContextProvider;
