import React, { useState } from 'react';
import { Card, Icon, Button, Popup, Image } from 'semantic-ui-react';
import CollaboratorList from './collaboration/CollaboratorList';
import './ResourceCard.css';
import InstanceSettings from './InstanceSettings';
import flagsmith from 'flagsmith';
import { Button as OdysseyButton, Dialog } from '@okta/odyssey-react-mui';
import { hasUserFacingSetting } from '../util/Settings';

const ResourceCard = ({ resource, waiting, attachEvent }) => {
  const [instanceSettings, setInstanceSettings] = useState(
    getPersonalSettings()
  );
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  function getPersonalSettings() {
    var personalSettings = [];
    if (resource.settings && resource.settings['awsAccountId']) {
      if (flagsmith.getTrait('aws_account_id')) {
        var accountId = flagsmith.getTrait('aws_account_id').toString();
        //this is needed as flagsmith forces all numberic traits to number and trims leading zeros
        if (accountId.length < 12) {
          accountId = String(accountId).padStart(12, '0');
        }
        personalSettings['awsAccountId'] = accountId;
      }
    }
    if (
      resource.settings &&
      resource.settings['awsRegion'] &&
      flagsmith.getTrait('aws_preferred_region')
    ) {
      personalSettings['awsRegion'] = flagsmith.getTrait(
        'aws_preferred_region'
      );
    }
    return { ...personalSettings };
  }

  function attachResource() {
    attachEvent(resource.resource_id, instanceSettings);
  }

  return (
    <Card key={resource.resource_id}>
      <Card.Content className="cardHeader">
        <Card.Header>
          {resource.name}
          {resource.logo && (
            <Image floated="right" size="mini" src={resource.logo} />
          )}
        </Card.Header>
        {resource.category !== 'managed' && (
          <Card.Meta>
            <CollaboratorList entity={resource} />
          </Card.Meta>
        )}
      </Card.Content>
      <Card.Content className="cardContent">
        <Card.Description className="pre-line-text">
          {resource.description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Popup
          content="This resource will be able to make changes to your tenant configuration. If you have already deployed other resources, applications or configured your tenant attaching this resource may create conflicts."
          trigger={
            <span>
              <Icon name="warning circle" size="" /> Can configure tenant
            </span>
          }
        />
      </Card.Content>
      <Card.Content extra className="cardFoot">
        {resource.state === 'active' || resource.state === 'preview' ? (
          <span>
            {hasUserFacingSetting(resource.settings) ? (
              <>
                <Button
                  className="card-action branded"
                  disabled={waiting}
                  onClick={() => setSettingsDialogOpen(true)}
                >
                  Attach
                </Button>
                <Dialog
                  isOpen={settingsDialogOpen}
                  title={`${resource.name} Settings`}
                  primaryCallToActionComponent={
                    <OdysseyButton
                      label="Continue"
                      disabled={waiting}
                      onClick={() => attachResource()}
                    />
                  }
                  secondaryCallToActionComponent={
                    <OdysseyButton
                      label="Cancel"
                      onClick={() => setSettingsDialogOpen(false)}
                      variant="floating"
                    />
                  }
                  onClose={() => setSettingsDialogOpen(false)}
                >
                  Customize the settings for this resource or proceed with the
                  default settings.
                  <InstanceSettings
                    defaultSettings={instanceSettings}
                    instanceSettings={resource.settings}
                    editMode={true}
                    onUpdate={setInstanceSettings}
                  />
                </Dialog>
              </>
            ) : (
              <Button
                className="card-action branded"
                disabled={waiting}
                onClick={() => {
                  attachEvent(resource.resource_id);
                  setSettingsDialogOpen(false);
                }}
              >
                Attach
              </Button>
            )}
          </span>
        ) : (
          <Button className="card-action" disabled>
            {resource.state}
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};
export default ResourceCard;
