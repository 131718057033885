import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Config from './Config';

const DemoContext = createContext({
  demos: {},
  idps: {},
  loading: {},
  error: {},
  refreshContext: () => {},
});

const DemoContextProvider = ({ children }) => {
  const [error, setError] = useState();
  const [demos, setDemos] = useState([]);
  const [idps, setIdps] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const refreshContext = useCallback(async () => {
    setError();
    setLoading(true);
    if (isAuthenticated) {
      try {
        var demoResponse = await axios.get(
          Config.resourceServer.demoAPI + '/demonstration',
          {
            headers: {
              Authorization: 'Bearer ' + (await getAccessTokenSilently()),
            },
          }
        );
        demoResponse.data.sort((a, b) => a.label.localeCompare(b.label));
        setDemos(demoResponse.data);

        var idpResponse = axios.get(Config.resourceServer.demoAPI + '/idp', {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        });
        setIdps((await idpResponse).data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      setDemos([]);
      setIdps([]);
    }
  }, [getAccessTokenSilently, isAuthenticated, setDemos]);

  useEffect(() => {
    if (isAuthenticated) {
      refreshContext();
    } else {
      setDemos([]);
    }
  }, [refreshContext, isAuthenticated, setDemos]);

  return (
    <DemoContext.Provider
      value={{ demos, idps, loading, error, refreshContext }}
    >
      {children}
    </DemoContext.Provider>
  );
};
export const useDemoContext = () => useContext(DemoContext);
export default DemoContextProvider;
