import React, { useState } from 'react';
import { useOpportunityContext } from '../../OpportunityContext';
import { Autocomplete } from '@okta/odyssey-react-mui';

const OpportunitySelect = ({ allowEdit, opportunitySelected, error }) => {
  const { opportunities } = useOpportunityContext();
  const [selected, setSelected] = useState(null);

  function buildOptions(opportunities) {
    const options = [];
    opportunities.forEach((element) => {
      options.push({
        id: element.name,
        label: element.name,
        value: element.opportunity_id,
      });
    });
    return options;
  }

  function onChange(event, arg) {
    const { label = null, value = null } = arg || {};
    if (label && value) {
      setSelected({ label: label, value });
      opportunitySelected({
        target: {
          id: 'opportunity',
          name: 'opportunity',
          value: value,
          label: label,
        },
      });
    } else {
      setSelected(null);
      opportunitySelected({
        target: {
          id: 'opportunity',
          name: 'opportunity',
          value: null,
          label: null,
        },
      });
    }
  }

  return (
    <Autocomplete
      label="Opportunity"
      hint="Select the opportunity that this demonstration is supporting. "
      options={buildOptions(opportunities)}
      isDisabled={!allowEdit}
      onChange={onChange}
      value={selected}
      getIsOptionEqualToValue={(option, value) => option.value === value.value}
      errorMessage={error}
    />
  );
};
export default OpportunitySelect;
