import React from 'react';
import { Container } from 'semantic-ui-react';
import Demos from '../components/Demos';
import Applications from '../components/Applications';
import Resources from '../components/Resources';
import Enablement from '../components/Enablement';
import { useFlags } from 'flagsmith/react';

const Home = () => {
  const flags = useFlags(['resources']);
  return (
    <Container>
      <Enablement />
      <Demos />
      <Applications />
      {flags.resources.enabled && <Resources />}
    </Container>
  );
};
export default Home;
