import React, { useState, useEffect } from 'react';
import { Container, Form, Checkbox } from 'semantic-ui-react';

const Dependencies = ({ dependencies, onChange, allowEdit }) => {
  const [supportsCIC, setSupportsCIC] = useState(
    dependencies?.idp?.types?.includes('customer-identity')
  );
  const [supportsOCIS, setSupportsOCIS] = useState(
    dependencies?.idp?.types?.includes('okta-customer-identity')
  );
  const [supportsWIC, setSupportsWIC] = useState(
    dependencies?.idp?.types?.includes('workforce-identity')
  );

  function handleChange(event) {
    switch (event.target.id) {
      case 'dependency_cic':
        setSupportsCIC(event.target.checked);
        break;
      case 'dependency_wic':
        setSupportsWIC(event.target.checked);
        break;
      case 'dependency_ocis':
        setSupportsOCIS(event.target.checked);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setSupportsCIC(dependencies?.idp?.types?.includes('customer-identity'));
    setSupportsOCIS(
      dependencies?.idp?.types?.includes('okta-customer-identity')
    );
    setSupportsWIC(dependencies?.idp?.types?.includes('workforce-identity'));
  }, [dependencies]);

  useEffect(() => {
    var types = [];
    if (supportsCIC) {
      types.push('customer-identity');
    }
    if (supportsOCIS) {
      types.push('okta-customer-identity');
    }
    if (supportsWIC) {
      types.push('workforce-identity');
    }

    var updated = {
      idp: { types: types },
    };
    onChange(updated);
  }, [supportsCIC, supportsOCIS, supportsWIC, onChange]);

  return (
    <Container>
      <Form.Field>
        <Checkbox
          id="dependency_cic"
          label="Supports Customer Identity Cloud"
          checked={supportsCIC}
          value={supportsCIC}
          onChange={handleChange}
          control={Checkbox}
          disabled={!allowEdit}
        />
      </Form.Field>

      <Form.Field>
        <Checkbox
          id="dependency_wic"
          label="Supports Workforce Identity Cloud"
          checked={supportsWIC}
          value={supportsWIC}
          onChange={handleChange}
          control={Checkbox}
          disabled={!allowEdit}
        />
      </Form.Field>

      <Form.Field>
        <Checkbox
          id="dependency_ocis"
          label="Supports Customer Identity Solution"
          checked={supportsOCIS}
          value={supportsOCIS}
          onChange={handleChange}
          control={Checkbox}
          disabled={!allowEdit}
        />
      </Form.Field>
    </Container>
  );
};
export default Dependencies;
