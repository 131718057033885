import React from 'react';
import { Table } from 'semantic-ui-react';
import './MetaData.css';

const MetaData = ({ meta_data }) => {
  var created_on = new Date(meta_data.created_on);
  var modified_on = new Date(meta_data.last_modified_on);

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return (
    <Table
      fixed
      unstackable
      className="metaDataTable"
      compact
      basic="very"
      singleLine
      textAlign="center"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Created By</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Updated By</Table.HeaderCell>
          <Table.HeaderCell>Updated</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Row>
        <Table.Cell>{meta_data.created_by}</Table.Cell>
        <Table.Cell>
          {new Intl.DateTimeFormat(undefined, dateOptions).format(created_on)}
        </Table.Cell>
        <Table.Cell>{meta_data.last_modified_by}</Table.Cell>
        <Table.Cell>
          {new Intl.DateTimeFormat(undefined, dateOptions).format(modified_on)}
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};
export default MetaData;
