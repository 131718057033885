import { useState } from 'react';
import {
  Container,
  Header,
  Form,
  Button,
  Segment,
  Input,
  Divider,
} from 'semantic-ui-react';

const BrandingPrompt = ({ step, demoName, func_complete }) => {
  const [waiting, setWaiting] = useState(false);
  const [uri, setUri] = useState('https://okta.com');

  async function submit() {
    setWaiting(true);
    var action = step;
    action.settings = { prospectUrl: uri };
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete();
  }

  function validateForm() {}

  function handleChange(event) {
    setUri(event.target.value);
  }

  return (
    <Container>
      <Container>
        <Segment basic>
          <Header>Demonstration Branding</Header>
          <Container>
            First impressions are important. Providing an Identity experience
            that is seamless yet familar goes a long way in enabling your users
            to meet their business goals while ensuring a high level of
            security. Using images, fonts and colours that tie together in an
            already established user experience accelerates onboarding and
            migration to a new Identity Solution. Applying branding that is
            sourced from your Company's website provides an initial look and
            feel of familarity to your Identity Provider. This component sets a
            customised logo, colour schemes and other UI elements that align to
            an existing theme for a unified and seamless user experience.
          </Container>
          <Divider hidden />
          <Form>
            <Form.Field
              id="name"
              label={<label>Company Website</label>}
              control={Input}
              value={uri}
              onChange={handleChange}
              type="url"
              onKeyUp={validateForm}
              style={{ textTransform: 'lowercase' }}
            />
            <Button
              disabled={waiting}
              className="branded"
              type="submit"
              onClick={submit}
            >
              Apply
            </Button>
            <Button disabled={waiting} className="" onClick={skip}>
              Skip
            </Button>
          </Form>
        </Segment>
      </Container>
    </Container>
  );
};
export default BrandingPrompt;
