export const SettingTypeOptions = [
  {
    key: 'text',
    text: 'Text',
    value: 'text',
  },
  {
    key: 'secret',
    text: 'Secret',
    value: 'secret',
  },
  {
    key: 'bool',
    text: 'Boolean',
    value: 'bool',
  },
];
export const BooleanSettingOptions = [
  {
    key: 'true',
    text: 'True',
    value: true,
  },
  {
    key: 'false',
    text: 'False',
    value: false,
  },
];
