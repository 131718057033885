import React, { useState } from 'react';
import { Container, Form, Button, Table } from 'semantic-ui-react';
import { BooleanSettingOptions } from '../components/ComponentSettingsConstants';
import AWSRegionSetting from './settings/AWSRegionSetting';

const InstanceSettings = ({
  defaultSettings,
  instanceSettings,
  editMode,
  onUpdate,
}) => {
  const [settings, setSettings] = useState(defaultSettings);

  function handleChange(event, { name, value }) {
    var settingKey = name.replace('settings.', '');
    var updateSettings = settings;
    if (value.length > 0 || typeof value === 'boolean') {
      updateSettings[settingKey] = value;
    } else {
      delete updateSettings[settingKey];
    }
    setSettings({ ...updateSettings });
    onUpdate(updateSettings);
  }

  function renderSettingRow(key) {
    if (key.startsWith('_')) {
      return null;
    } else {
      return (
        <Table.Row>
          <Table.Cell textAlign="right">{key}</Table.Cell>
          <Table.Cell>{renderSettingField(key)}</Table.Cell>
          {key !== 'awsAccountId' && (
            <Table.Cell>
              {editMode && settings[key] && (
                <Button
                  className="rowAction"
                  content="Use Default"
                  icon="repeat"
                  labelPosition="left"
                  onClick={() => {
                    handleChange(null, {
                      name: 'settings.' + key,
                      value: instanceSettings[key].default,
                    });
                  }}
                />
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    }
  }

  function renderSettingField(key) {
    //handle one off fields
    if (key === 'awsRegion') {
      return (
        <AWSRegionSetting
          name="awsRegion"
          instanceValue={settings[key]}
          defaultValue="us-east-1"
          readOnly={!editMode}
          onChange={handleChange}
        />
      );
    }
    if (key === 'awsAccountId') {
      return (
        <Form.Input
          inline
          id={'settings.' + key}
          key={'settings.' + key}
          name={'settings.' + key}
          value={settings[key] ? settings[key] : ''}
          maxLength="1024"
          readOnly={true}
          disabled
          fluid
          autofocus
        />
      );
    }
    //render by field type
    switch (instanceSettings[key].type) {
      case 'bool': {
        return (
          <Form.Dropdown
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key]}
            onChange={handleChange}
            options={BooleanSettingOptions}
            disabled={!editMode}
            placeholder={
              '(default) ' + instanceSettings[key].default.toString()
            }
            selection
            fluid
          />
        );
      }
      case 'secret': {
        return (
          <Form.Input
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key] ? settings[key] : ''}
            onChange={handleChange}
            maxLength="1024"
            readOnly={!editMode}
            placeholder={'(default) *****'}
            fluid
            type="password"
          />
        );
      }
      default: {
        return (
          <Form.Input
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key] ? settings[key] : ''}
            onChange={handleChange}
            maxLength="1024"
            readOnly={!editMode}
            placeholder={'(default) ' + instanceSettings[key].default}
            fluid
            autofocus
          />
        );
      }
    }
  }

  return (
    <Container>
      {instanceSettings ? (
        <Container>
          <Table singleLine striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width={3}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {Object.keys(instanceSettings).map((key) => renderSettingRow(key))}
          </Table>
        </Container>
      ) : null}
    </Container>
  );
};

export default InstanceSettings;
