import { useFlags } from 'flagsmith/react';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Image, Input, Icon, Header } from 'semantic-ui-react';
import Gravatar from 'gravatar';
import './CollaborationModal.css';
import { addCollaborator, removeCollaborator } from '../../services/DemoAPI';
import ErrorMessage from '../ErrorMessage';
import { useAuth0 } from '@auth0/auth0-react';

function CollaborationModal({
  entity,
  entityType,
  trigger,
  function_reloaddata,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const flags = useFlags(['collaboration']);
  const [hasChanges, setHasChanges] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [err, setErr] = useState();

  useEffect(() => {
    if (open) {
      setErr(null);
    }
    if (!open) {
      if (hasChanges) {
        function_reloaddata();
        setHasChanges(false);
      }
    }
  }, [open, function_reloaddata, hasChanges]);

  function getComponentRoute() {
    if (entityType === 'demonstration') {
      return entityType + '/' + entity.name;
    } else if (entityType === 'application') {
      return 'applications/' + entity.application_id;
    } else if (entityType === 'resource') {
      return 'resources/' + entity.resource_id;
    } else {
      return '';
    }
  }

  async function inviteCollaborator(value) {
    try {
      setWaiting(true);
      await addCollaborator(
        await getAccessTokenSilently(),
        getComponentRoute(),
        { email: value, role: 'owner' }
      );
      entity.collaborators.push({ principal: value });
      document.getElementById('addCollaboratorInput').value = '';
      setHasChanges(true);
    } catch (err) {
      setErr(err);
    } finally {
      setWaiting(false);
    }
  }

  async function deleteCollaborator(value) {
    try {
      setWaiting(true);
      await removeCollaborator(
        await getAccessTokenSilently(),
        getComponentRoute(),
        { email: value, role: 'owner' }
      );
      entity.collaborators = entity.collaborators.filter(
        (item) => item.principal !== value
      );
      setHasChanges(true);
    } catch (err) {
      setErr(err);
    } finally {
      setWaiting(false);
    }
  }

  if (flags.collaboration.enabled && entity?.collaborators) {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<span>{trigger}</span>} //this span is needed to handle prop spread https://github.com/Semantic-Org/Semantic-UI-React/issues/1187#issuecomment-273988903
        className="collaborationModal"
      >
        <Modal.Header>Collaborators on {entity.name}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Users here will be able to see and modify <b>{entity.name}</b> in
              builder.demo.okta.com
            </p>
            {entity.collaborators.map((collaborator) => (
              <div>
                <Image
                  className="collaborator"
                  src={Gravatar.url(collaborator.principal, {
                    protocol: 'https',
                    s: '100',
                    d: 'https://cdn.demo.okta.com/images/okta-icon.png',
                  })}
                  avatar
                />
                {collaborator.principal}
                {entity.collaborators.length > 1 && (
                  <Icon
                    name="trash"
                    color="red"
                    onClick={() => deleteCollaborator(collaborator.principal)}
                  />
                )}
              </div>
            ))}
            <Header as="h4">Invite collaborator</Header>
            <Input
              placeholder="email"
              action={{
                loading: waiting,
                disabled: waiting,
                icon: 'plus',
                className: 'branded',
                onClick: () => {
                  inviteCollaborator(
                    document.getElementById('addCollaboratorInput').value
                  );
                },
              }}
              input={{
                id: 'addCollaboratorInput',
              }}
            />
          </Modal.Description>
          {err && <ErrorMessage error={err} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={waiting}
            className="branded"
            content="Done"
            onClick={() => setOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    );
  } else {
    return <span>{trigger}</span>;
  }
}

export default CollaborationModal;
