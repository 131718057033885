import { Image, Icon } from 'semantic-ui-react';
import './IdpTypeLabel.css';
import IdpType from './IdpType';

const IdpTypeLabel = ({ idps, idpid }) => {
  var result = idps.find((obj) => {
    return obj.idp_id === idpid;
  });
  if (result) {
    switch (result.type) {
      case 'customer-identity':
        return (
          <div>
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/CIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </div>
        );
      case 'okta-customer-identity':
        return (
          <div>
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </div>
        );
      case 'workforce-identity':
        return (
          <div>
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </div>
        );
      default:
        break;
    }
  } else {
    return (
      <div>
        <Icon name="question circle outline" /> Unknown
      </div>
    );
  }
};
export default IdpTypeLabel;
