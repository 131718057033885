import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import {
  Container,
  Header,
  Form,
  Loader,
  Button,
  Segment,
  Input,
  Grid,
  Divider,
  FormField,
} from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import MetaData from '../components/MetaData';
import ComponentSettings from '../components/ComponentSettings';
import Dependencies from '../components/Dependencies';
import { deleteResource, updateResource } from '../services/DemoAPI';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import { useFlags } from 'flagsmith/react';
import flagsmith from 'flagsmith';

const ResourceDetails = () => {
  const navigate = useNavigate();

  let params = useParams();
  const [resource, setResource] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [description, setDescription] = useState('');
  const [dependencies, setDependencies] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [createHook, setCreateHook] = useState();
  const [createHookError, setCreateHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [destroyHook, setDestroyHook] = useState();
  const [destroyHookError, setDestroyHookError] = useState();
  const [settings, setSettings] = useState({});
  const [documentationLink, setDocumentationLink] = useState();
  const [documentationLinkError, setDocumentationLinkError] = useState();
  const [supportLink, setSupportLink] = useState();
  const [supportLinkError, setSupportLinkError] = useState();
  const [logoLink, setLogoLink] = useState();
  const [logoLinkError, setLogoLinkError] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoLinkError, setVideoLinkError] = useState();
  const [tags, setTags] = useState();
  const flags = useFlags(['collaboration']);

  const [error, setError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getResource = useCallback(async () => {
    axios
      .get(Config.resourceServer.demoAPI + '/resources/' + params.resourceId, {
        headers: {
          Authorization: 'Bearer ' + (await getAccessTokenSilently()),
        },
      })
      .then((response) => {
        setResource(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setResource, getAccessTokenSilently, params.resourceId]);

  const initalizeFields = useCallback(async () => {
    if (resource) {
      setName(resource.name);
      setDescription(resource.description);
      if (resource.dependsOn) {
        setDependencies(resource.dependsOn);
      }
      if (resource.settings) {
        setSettings(resource.settings);
      }
      if (resource.hooks && resource.hooks.request) {
        setRequestHook(resource.hooks.request);
      }
      if (resource.hooks && resource.hooks.create) {
        setCreateHook(resource.hooks.create);
      }
      if (resource.hooks && resource.hooks.update) {
        setUpdateHook(resource.hooks.update);
      }
      if (resource.hooks && resource.hooks.destroy) {
        setDestroyHook(resource.hooks.destroy);
      }
      if (resource.documentationLink) {
        setDocumentationLink(resource.documentationLink);
      }
      if (resource.supportLink) {
        setSupportLink(resource.supportLink);
      }
      if (resource.logo) {
        setLogoLink(resource.logo);
      }
      if (resource.video) {
        setVideoLink(resource.video);
      }
      if (resource.tags) {
        setTags(resource.tags);
      }
    }
  }, [resource]);

  async function submit() {
    if (validateForm()) {
      setWaiting(true);
      updateResource(
        await getAccessTokenSilently(),
        resource.resource_id,
        name,
        description,
        {
          request: requestHook,
          create: createHook,
          update: updateHook,
          destroy: destroyHook,
        },
        settings,
        dependencies,
        documentationLink,
        supportLink,
        videoLink,
        logoLink,
        tags
      )
        .then((response) => {
          setResource(response.data);
          setEditMode(false);
          setWaiting(false);
        })
        .catch((error) => {
          setError(error);
          setEditMode(false);
          setWaiting(false);
        });
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getResource();
    } else {
      setResource();
    }
  }, [getResource, isAuthenticated, getAccessTokenSilently, setResource]);

  useEffect(() => {
    initalizeFields();
  }, [initalizeFields, resource, editMode]);

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'logoLink':
        setLogoLink(event.target.value);
        break;
      case 'documentationLink':
        setDocumentationLink(event.target.value);
        break;
      case 'videoLink':
        setVideoLink(event.target.value);
        break;
      case 'supportLink':
        setSupportLink(event.target.value);
        break;
      case 'requestHook':
        setRequestHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'createHook':
        setCreateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'updateHook':
        setUpdateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'destroyHook':
        setDestroyHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      default:
        break;
    }
  }

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (name === '') {
      setNameError('Please enter a name for your resource.');
      result = false;
    } else {
      setNameError();
    }
    if (logoLink && !logoLink.match(validURlregex)) {
      setDocumentationLinkError('Please enter a valid URL to your logo');
      result = false;
    } else {
      setLogoLinkError();
    }
    if (documentationLink && !documentationLink.match(validURlregex)) {
      setDocumentationLinkError(
        'Please enter a valid URL to your documentation'
      );
      result = false;
    } else {
      setDocumentationLinkError();
    }
    if (videoLink && !videoLink.match(validURlregex)) {
      setVideoLinkError('Please enter a valid URL to your video');
      result = false;
    } else {
      setVideoLinkError();
    }
    if (supportLink && !supportLink.match(validURlregex)) {
      setSupportLinkError('Please enter a valid URL to your documentation');
      result = false;
    } else {
      setSupportLinkError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setRequestHookError();
    }
    if (createHook && !createHook.match(validURlregex)) {
      setCreateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setCreateHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setUpdateHookError();
    }
    if (destroyHook && !destroyHook.match(validURlregex)) {
      setDestroyHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setDestroyHookError();
    }
    if (!requestHook && !createHook && !updateHook && !destroyHook) {
      setRequestHookError('Please enter at least one webhook');
      setCreateHookError('Please enter at least one webhook');
      setUpdateHookError('Please enter at least one webhook');
      setDestroyHookError('Please enter at least one webhook');
    }
    return result;
  }

  async function submitDelete() {
    setWaiting(true);
    deleteResource(await getAccessTokenSilently(), resource.resource_id)
      .then(() => {
        navigate('/');
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Container className="appComponent">
      <NavigationButton destination="/" />
      <Segment>
        {resource ? (
          <Container>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={12}>
                  <Header className="contentHeader">
                    Resource: {resource.name}
                  </Header>
                  <CollaborationModal
                    entity={resource}
                    entityType="resource"
                    trigger={
                      <CollaboratorList
                        entity={resource}
                        includeManage={flags.collaboration?.enabled}
                      />
                    }
                    function_reloaddata={getResource}
                  ></CollaborationModal>
                </Grid.Column>
                <Grid.Column width={4}>
                  {editMode ? (
                    <span>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded basic"
                        type="reset"
                        onClick={() => {
                          setEditMode(false);
                          getResource();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded"
                        type="submit"
                        onClick={() => submit()}
                      >
                        Update
                      </Button>
                    </span>
                  ) : (
                    <span>
                      <Button
                        floated="right"
                        disabled={waiting}
                        type="button"
                        color="red"
                        onClick={() => {
                          submitDelete();
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded"
                        type="button"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        Edit
                      </Button>
                    </span>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header.Subheader className="meta">
              <MetaData meta_data={resource.meta_data} />
            </Header.Subheader>

            <Divider />
          </Container>
        ) : (
          <Header className="contentHeader">Resource:</Header>
        )}
        {error ? <ErrorMessage error={error} /> : null}

        <Container>
          {resource ? (
            <Container>
              <Form loading={waiting}>
                <FormField>
                  <label>Category</label>
                  <CategoryLabel category={resource.category} />
                  {flagsmith.getTrait('connection') === 'employee' &&
                    resource.category === 'private' && (
                      <a
                        className="padded"
                        href="https://form.asana.com/?k=PT8Qfq-ZNf9n-H9CuXrRFA&d=10114792544943"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Share to Community
                      </a>
                    )}
                </FormField>
                <Form.Field
                  id="componentID"
                  control={Input}
                  label="Component ID"
                  value={resource.resource_id}
                  readOnly
                />
                <Form.Field
                  id="name"
                  control={Input}
                  label="Name"
                  value={name}
                  onChange={handleChange}
                  error={nameError}
                  onBlur={validateForm}
                  maxLength="30"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="description"
                  value={description}
                  onChange={handleChange}
                  label="Description"
                  control="textarea"
                  rows="3"
                  maxLength="1024"
                  placeholder="A custom demo."
                  readOnly={!editMode}
                />

                <Form.Field
                  id="logoLink"
                  value={logoLink}
                  onChange={handleChange}
                  error={logoLinkError}
                  onBlur={validateForm}
                  label="Logo Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="documentationLink"
                  value={documentationLink}
                  onChange={handleChange}
                  error={documentationLinkError}
                  onBlur={validateForm}
                  label="Documentation Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="videoLink"
                  value={videoLink}
                  onChange={handleChange}
                  error={videoLinkError}
                  onBlur={validateForm}
                  label="Video Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="supportLink"
                  value={supportLink}
                  onChange={handleChange}
                  error={supportLinkError}
                  onBlur={validateForm}
                  label="Support Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />

                <Dependencies
                  dependencies={dependencies}
                  onChange={setDependencies}
                  allowEdit={editMode}
                />

                <Header as="h3">Resource Settings</Header>
                <ComponentSettings
                  settings={settings}
                  allowEdit={editMode}
                  setSettings={setSettings}
                />

                <Header as="h3">Lifecycle Hooks</Header>
                <Form.Field
                  id="requestHook"
                  value={requestHook}
                  onChange={handleChange}
                  label="Requested Event"
                  control={Input}
                  placeholder="https://example.com/requested"
                  error={requestHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="createHook"
                  value={createHook}
                  onChange={handleChange}
                  label="Created Event"
                  control={Input}
                  placeholder="https://example.com/created"
                  error={createHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="updateHook"
                  value={updateHook}
                  onChange={handleChange}
                  label="Updated Event"
                  control={Input}
                  placeholder="https://example.com/updated"
                  error={updateHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="destroyHook"
                  value={destroyHook}
                  onChange={handleChange}
                  label="Deleted Event"
                  control={Input}
                  placeholder="https://example.com/deleted"
                  error={destroyHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
              </Form>

              <Divider />
            </Container>
          ) : (
            <Loader active inline />
          )}
        </Container>
      </Segment>
    </Container>
  );
};
export default ResourceDetails;
