import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import { Container, Header, Loader, Card, Divider } from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';

import { useFlags } from 'flagsmith/react';
import './DemoResources.css';
import ResourceCard from '../components/ResourceCard';
import { getIDP, listResources } from '../services/DemoAPI';
import { BreadcrumbList } from '@okta/odyssey-react-mui';

const DemoResources = () => {
  let params = useParams();
  const navigate = useNavigate();

  const [demo, setDemo] = useState();
  const [idp, setIdp] = useState();
  const [resources, setResources] = useState();
  const [resourceCount, setResourceCount] = useState(0);
  const [privateResources, setPrivateResources] = useState();
  const [privateResourceCount, setPrivateResourceCount] = useState(0);
  const [communityResources, setCommunityResources] = useState();
  const [communityResourceCount, setCommunityResourceCount] = useState(0);
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['community_access']);

  const getDemo = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      axios
        .get(
          Config.resourceServer.demoAPI + '/demonstration/' + params.demoName,
          {
            headers: {
              Authorization: 'Bearer ' + (await getAccessTokenSilently()),
            },
          }
        )
        .then((response) => {
          setDemo(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setDemo();
    }
  }, [getAccessTokenSilently, isAuthenticated, setDemo, params.demoName]);

  useEffect(() => {
    async function getIDPDetails() {
      getIDP(await getAccessTokenSilently(), demo.idp_id)
        .then((response) => {
          setIdp(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    }
    if (demo) {
      getIDPDetails();
    }
  }, [demo, getAccessTokenSilently]);

  const getResources = useCallback(async () => {
    if (isAuthenticated && demo && idp) {
      listResources(await getAccessTokenSilently(), 'managed', idp.type).then(
        (response) => {
          var availableResources = response.data;
          setResourceCount(availableResources.length);
          for (
            let instanceIndex = 0;
            instanceIndex < demo.resource_instances.length;
            instanceIndex++
          ) {
            const element = demo.resource_instances[instanceIndex];
            let index = availableResources.findIndex(
              (x) => x.resource_id === element.resourceId
            );
            if (index !== -1) {
              availableResources.splice(index, 1);
            }
          }
          setResources(availableResources);
        }
      );
      listResources(await getAccessTokenSilently(), 'private', idp.type).then(
        (response) => {
          var availableResources = response.data;
          setPrivateResourceCount(availableResources.length);
          for (
            let instanceIndex = 0;
            instanceIndex < demo.resource_instances.length;
            instanceIndex++
          ) {
            const element = demo.resource_instances[instanceIndex];
            let index = availableResources.findIndex(
              (x) => x.resource_id === element.resourceId
            );
            if (index !== -1) {
              availableResources.splice(index, 1);
            }
          }
          setPrivateResources(availableResources);
        }
      );
      if (flags.community_access.enabled) {
        listResources(
          await getAccessTokenSilently(),
          'community',
          idp.type
        ).then((response) => {
          var availableResources = response.data;
          setCommunityResourceCount(availableResources.length);
          for (
            let instanceIndex = 0;
            instanceIndex < demo.resource_instances.length;
            instanceIndex++
          ) {
            const element = demo.resource_instances[instanceIndex];
            let index = availableResources.findIndex(
              (x) => x.resource_id === element.resourceId
            );
            if (index !== -1) {
              availableResources.splice(index, 1);
            }
          }
          setCommunityResources(availableResources);
        });
      }
    } else {
      setResources();
      setPrivateResources();
      setCommunityResources();
    }
  }, [
    demo,
    getAccessTokenSilently,
    isAuthenticated,
    flags.community_access,
    idp,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getDemo();
    } else {
      setDemo();
    }
  }, [getDemo, isAuthenticated, getAccessTokenSilently, setDemo]);

  useEffect(() => {
    getResources();
  }, [demo, getResources]);

  async function attachResource(id, settings) {
    setWaiting(true);
    var data = JSON.stringify({
      resourceId: id,
      settings: settings,
    });

    var config = {
      method: 'post',
      url:
        Config.resourceServer.demoAPI +
        '/demonstration/' +
        params.demoName +
        '/resources',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessTokenSilently()),
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        navigate('/demo/' + params.demoName);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Container className="appComponent">
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <NavigationButton
          destination={`/demo/${params.demoName}`}
          msg={params.demoName}
        />
        <i href="">Demo Resource Library</i>
      </BreadcrumbList>
      <Header className="contentHeader">
        Demo Resource Library{' '}
        <Header.Subheader>Add resource to {params.demoName}</Header.Subheader>
      </Header>
      {error ? <ErrorMessage error={error} retryAction={getResources} /> : null}

      <Header as="h3">
        Managed resources
        {resources && (
          <span className="resourceCount">
            ({resources.length}/{resourceCount})
          </span>
        )}
        <Header.Subheader>
          Resources created and run by Demo Engineering.
        </Header.Subheader>
      </Header>
      {resources ? (
        <Container>
          {resources.length !== 0 ? (
            <Card.Group>
              {resources.map((resource) => (
                <ResourceCard
                  resource={resource}
                  waiting={waiting}
                  attachEvent={attachResource}
                />
              ))}
            </Card.Group>
          ) : (
            <Container>
              Could not find any managed resources not already attached.
            </Container>
          )}
        </Container>
      ) : (
        <Loader active inline />
      )}

      <Divider />

      <Header as="h3">
        Your resources
        {privateResources && (
          <span className="resourceCount">
            ({privateResources.length}/{privateResourceCount})
          </span>
        )}
        <Header.Subheader>
          Custom demonstration resources you have created.
        </Header.Subheader>
      </Header>
      {privateResources ? (
        <Container>
          {privateResources.length !== 0 ? (
            <Card.Group>
              {privateResources.map((resource) => (
                <ResourceCard
                  resource={resource}
                  waiting={waiting}
                  attachEvent={attachResource}
                />
              ))}
            </Card.Group>
          ) : (
            <Container>
              {privateResourceCount > 0
                ? 'Could not find any of your custom resources not already attached.'
                : 'You do not have any custom resources yet.'}
            </Container>
          )}
        </Container>
      ) : (
        <Loader active inline />
      )}

      {flags.community_access.enabled && (
        <Container>
          <Divider />
          <Header as="h3">
            Community Resources
            {communityResources && (
              <span className="resourceCount">
                ({communityResources.length}/{communityResourceCount})
              </span>
            )}
            <Header.Subheader>
              Demonstrations created by the Okta SE community.
            </Header.Subheader>
          </Header>

          {communityResources ? (
            <Container>
              {communityResources.length !== 0 ? (
                <Card.Group>
                  {communityResources.map((resource) => (
                    <ResourceCard
                      resource={resource}
                      waiting={waiting}
                      attachEvent={attachResource}
                    />
                  ))}
                </Card.Group>
              ) : (
                <Container>
                  Could not find any community resources not already attached.
                </Container>
              )}
            </Container>
          ) : (
            <Loader active inline />
          )}
        </Container>
      )}
    </Container>
  );
};
export default DemoResources;
