import React, { useState } from 'react';
import {
  Container,
  Header,
  Form,
  Button,
  Segment,
  Input,
} from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import ComponentSettings from '../components/ComponentSettings';
import Dependencies from '../components/Dependencies';
import { createResource as createResourceAPI } from '../services/DemoAPI';

const CreateResource = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [description, setDescription] = useState('');
  const [dependencies, setDependencies] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [createHook, setCreateHook] = useState();
  const [createHookError, setCreateHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [destroyHook, setDestroyHook] = useState();
  const [destroyHookError, setDestroyHookError] = useState();
  const [settings, setSettings] = useState({});
  const [waiting, setWaiting] = useState(false);

  const [error, setError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  async function createResource() {
    if (isAuthenticated) {
      if (validateForm()) {
        setWaiting(true);
        createResourceAPI(
          await getAccessTokenSilently(),
          name,
          description,
          {
            request: requestHook,
            create: createHook,
            update: updateHook,
            destroy: destroyHook,
          },
          settings,
          dependencies
        )
          .then((response) => {
            navigate('/resource/' + response.data.resource_id, {
              replace: true,
            });
          })
          .catch((error) => {
            setError(error);
            setWaiting(false);
          });
      }
    }
  }

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (name === '') {
      setNameError('Please enter a name for your resource.');
      result = false;
    } else {
      setNameError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setRequestHookError();
    }
    if (createHook && !createHook.match(validURlregex)) {
      setCreateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setCreateHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setUpdateHookError();
    }
    if (destroyHook && !destroyHook.match(validURlregex)) {
      setDestroyHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setDestroyHookError();
    }
    if (!requestHook && !createHook && !updateHook && !destroyHook) {
      setRequestHookError('Please enter at least one webhook');
      setCreateHookError('Please enter at least one webhook');
      setUpdateHookError('Please enter at least one webhook');
      setDestroyHookError('Please enter at least one webhook');
    }
    return result;
  }

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'requestHook':
        setRequestHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'createHook':
        setCreateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'updateHook':
        setUpdateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'destroyHook':
        setDestroyHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      default:
        break;
    }
  }

  return (
    <Container className="appComponent">
      <NavigationButton destination="/" />
      <Segment>
        <Header className="contentHeader">Create New Resource</Header>
        {error ? <ErrorMessage error={error} /> : null}
        <Container>
          <Form loading={waiting}>
            <Form.Field
              id="name"
              control={Input}
              label="Name"
              value={name}
              onChange={handleChange}
              error={nameError}
              onBlur={validateForm}
              maxLength="30"
            />
            <Form.Field
              id="description"
              value={description}
              onChange={handleChange}
              label="Description"
              control="textarea"
              rows="3"
              maxLength="250"
              placeholder="A custom demo."
            />

            <Dependencies
              dependencies={dependencies}
              onChange={setDependencies}
              allowEdit={true}
            />

            <Header as="h3">Resource Settings</Header>
            <ComponentSettings
              settings={settings}
              allowEdit={true}
              setSettings={setSettings}
            />

            <Header as="h3">Lifecycle Hooks</Header>
            <Form.Field
              id="requestHook"
              value={requestHook}
              onChange={handleChange}
              label="Requested Event"
              control={Input}
              placeholder="https://example.com/requested"
              error={requestHookError}
              onBlur={validateForm}
            />
            <Form.Field
              id="createHook"
              value={createHook}
              onChange={handleChange}
              label="Created Event"
              control={Input}
              placeholder="https://example.com/created"
              error={createHookError}
              onBlur={validateForm}
            />
            <Form.Field
              id="updateHook"
              value={updateHook}
              onChange={handleChange}
              label="Updated Event"
              control={Input}
              placeholder="https://example.com/updated"
              error={updateHookError}
              onBlur={validateForm}
            />
            <Form.Field
              id="destroyHook"
              value={destroyHook}
              onChange={handleChange}
              label="Deleted Event"
              control={Input}
              placeholder="https://example.com/deleted"
              error={destroyHookError}
              onBlur={validateForm}
            />

            <Button className="branded" type="submit" onClick={createResource}>
              Create
            </Button>
          </Form>
        </Container>
      </Segment>
    </Container>
  );
};
export default CreateResource;
