import React from 'react';
import { List, Image, Popup, Icon } from 'semantic-ui-react';
import Gravatar from 'gravatar';
import './CollaboratorList.css';

function CollaboratorList({ entity, includeManage }) {
  const firstFive = entity?.collaborators
    ? entity?.collaborators?.slice(0, 5)
    : [];
  const remainingCount = entity?.collaborators?.length - 5;

  return (
    <List className="collaboratorAvatars">
      {firstFive.map((collaborator) => (
        <Popup
          content={collaborator.principal}
          key={collaborator.principal}
          position="bottom center"
          size="small"
          trigger={
            <Image
              className="collaborator"
              src={Gravatar.url(collaborator.principal, {
                protocol: 'https',
                s: '100',
                d: 'https://cdn.demo.okta.com/images/okta-icon.png',
              })}
              avatar
            />
          }
        />
      ))}
      {remainingCount > 0 && (
        // Render something for the remaining items here
        <span>+{remainingCount} more</span>
      )}
      {includeManage && entity.collaborators && <Icon name="cog" />}
    </List>
  );
}

export default CollaboratorList;
