import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Button,
  Header,
  Image,
  Card,
  Loader,
  Message,
} from 'semantic-ui-react';
import './demos.css';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Config from '../Config';
import ErrorMessage from './ErrorMessage';
import CategoryLabel from './CategoryLabel';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'flagsmith/react';

const Applications = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState();
  const [error, setError] = useState(null);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['app_limits']);

  const getApplications = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      axios
        .get(Config.resourceServer.demoAPI + '/applications', {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
          params: { owner: user.email },
        })
        .then((response) => {
          response.data.sort((a, b) => a.name.localeCompare(b.name));
          setApplications(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setApplications([]);
    }
  }, [getAccessTokenSilently, isAuthenticated, setApplications, user.email]);

  useEffect(() => {
    if (isAuthenticated) {
      getApplications();
    } else {
      setApplications([]);
    }
  }, [
    getApplications,
    isAuthenticated,
    getAccessTokenSilently,
    setApplications,
  ]);

  function handleOnClick(path) {
    navigate(path);
  }

  return (
    <div className="appComponent">
      <Header className="contentHeader">Your Applications</Header>

      {applications ? (
        <Container>
          <Button
            className="createActionButton branded"
            positive
            icon="plus"
            labelPosition="left"
            content="New Application"
            onClick={() => handleOnClick('/app')}
            disabled={
              flags.app_limits.enabled &&
              applications.length >= flags.app_limits.value
            }
          />
          {flags.app_limits.enabled &&
            applications.length >= flags.app_limits.value && (
              <span>
                You've reached the maximum number of applications allowed.
              </span>
            )}
          {applications.length !== 0 ? (
            <Card.Group className="hoverDarkerCard">
              {applications.map((application) => (
                <Card
                  key={application.id}
                  onClick={() =>
                    handleOnClick(`/app/${application.application_id}`)
                  }
                >
                  <Card.Content>
                    <Image
                      floated="right"
                      size="mini"
                      className="identicon"
                      src="https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-applications.svg"
                    />
                    <Card.Header>{application.name}</Card.Header>
                    <Card.Meta>
                      <CategoryLabel category={application.category} />
                    </Card.Meta>
                    <Card.Meta>
                      {new URL(application.baseAddress).host}
                    </Card.Meta>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          ) : (
            <Container>
              <Message>
                <Message.Header>
                  <Image
                    size="mini"
                    className="identicon"
                    src="https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-applications.svg"
                  />{' '}
                  Applications
                </Message.Header>
                <Message.Content>
                  <p>
                    Add your own custom applications to the demo library to
                    easily reuse components between demos or build a true custom
                    experience.
                  </p>
                  <p>
                    Learn how to get started building custom demo apps with our
                    docs{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.demo.okta.com/"
                    >
                      here
                    </a>
                    .
                  </p>
                </Message.Content>
              </Message>
            </Container>
          )}
        </Container>
      ) : error ? (
        <ErrorMessage error={error} retryAction={getApplications} />
      ) : (
        <Loader active inline />
      )}
    </div>
  );
};
export default Applications;
