import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './NavigationButton.css';

const NavigationButton = ({ destination, msg = 'back' }) => {
  const navigate = useNavigate();
  function handleOnClick(path) {
    navigate(path);
  }

  return (
    <span className="navButton" onClick={() => handleOnClick(destination)}>
      {msg === 'back' ? <Icon name="chevron left" /> : null}
      {msg}
    </span>
  );
};
export default NavigationButton;
