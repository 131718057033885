import { Button, Container, Header, Table } from 'semantic-ui-react';
import BooleanSetting from '../settings/BooleanSetting';
import TextSetting from '../settings/TextSetting';
import SecretSetting from '../settings/SecretSetting';
import { sortObjectByKeys } from '../../util/SortObjectByKeys';

const InstanceSettings = ({ settings, onChange, onRevertToDefault }) => {
  const protectedFields = ['awsAccountId', 'awsRegion'];

  return (
    <Container>
      <Header as="h3">Instance Settings</Header>
      <Table singleLine striped basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {Object.keys(sortObjectByKeys(settings)).map((key) => (
          <>
            {!key.startsWith('_') && (
              <Table.Row>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>
                  {settings[key].type === 'bool' && (
                    <BooleanSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      defaultValue={settings[key].default}
                      onChange={onChange}
                    />
                  )}
                  {settings[key].type === 'text' && (
                    <TextSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      defaultValue={settings[key].default}
                      readOnly={protectedFields.includes(key)}
                      disabled={protectedFields.includes(key)}
                      onChange={onChange}
                    />
                  )}
                  {settings[key].type === 'secret' && (
                    <SecretSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      onChange={onChange}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {settings[key].hasOwnProperty('instance') &&
                    !protectedFields.includes(key) && (
                      <Button
                        key={key}
                        className="rowAction"
                        content="Use Default"
                        icon="repeat"
                        labelPosition="left"
                        onClick={() => onRevertToDefault(key)}
                      />
                    )}
                </Table.Cell>
              </Table.Row>
            )}
          </>
        ))}
      </Table>
    </Container>
  );
};

export default InstanceSettings;
