import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Header,
  Form,
  Loader,
  Button,
  Segment,
  Input,
  Grid,
  Divider,
  FormField,
  Icon,
} from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import MetaData from '../components/MetaData';
import ComponentSettings from '../components/ComponentSettings';
import {
  deleteApplication,
  getApplication,
  updateApplication,
} from '../services/DemoAPI';
import Dependencies from '../components/Dependencies';
import { DemonstrationDiscoveryOptions } from '../components/DemonstrationDiscoveryConstants';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import { useFlags } from 'flagsmith/react';
import flagsmith from 'flagsmith';

const ApplicationDetails = () => {
  const navigate = useNavigate();

  let params = useParams();
  const [application, setApplication] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [baseAddress, setBaseAddress] = useState('');
  const [baseAddressError, setBaseAddressError] = useState();
  const [description, setDescription] = useState('');
  const [multiTenancy, setMultiTenancy] = useState(true);
  const [dependencies, setDependencies] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [createHook, setCreateHook] = useState();
  const [createHookError, setCreateHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [destroyHook, setDestroyHook] = useState();
  const [destroyHookError, setDestroyHookError] = useState();
  const [settings, setSettings] = useState({});
  const [documentationLink, setDocumentationLink] = useState();
  const [documentationLinkError, setDocumentationLinkError] = useState();
  const [supportLink, setSupportLink] = useState();
  const [supportLinkError, setSupportLinkError] = useState();
  const [logoLink, setLogoLink] = useState();
  const [logoLinkError, setLogoLinkError] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoLinkError, setVideoLinkError] = useState();
  const [tags, setTags] = useState();

  const [error, setError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['collaboration']);

  const getApp = useCallback(async () => {
    getApplication(await getAccessTokenSilently(), params.appId)
      .then((response) => {
        setApplication(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setApplication, getAccessTokenSilently, params.appId]);

  const initalizeFields = useCallback(async () => {
    if (application) {
      setName(application.name);
      setBaseAddress(application.baseAddress);
      setMultiTenancy(application.multiTenancy);
      setDescription(application.description);
      if (application.dependsOn) {
        setDependencies(application.dependsOn);
      }
      if (application.settings) {
        setSettings(application.settings);
      }
      if (application.hooks && application.hooks.request) {
        setRequestHook(application.hooks.request);
      }
      if (application.hooks && application.hooks.create) {
        setCreateHook(application.hooks.create);
      }
      if (application.hooks && application.hooks.update) {
        setUpdateHook(application.hooks.update);
      }
      if (application.hooks && application.hooks.destroy) {
        setDestroyHook(application.hooks.destroy);
      }
      if (application.documentationLink) {
        setDocumentationLink(application.documentationLink);
      }
      if (application.supportLink) {
        setSupportLink(application.supportLink);
      }
      if (application.logo) {
        setLogoLink(application.logo);
      }
      if (application.video) {
        setVideoLink(application.video);
      }
      if (application.tags) {
        setTags(application.tags);
      }
    }
  }, [application]);

  async function updateApp() {
    if (validateForm()) {
      setWaiting(true);
      updateApplication(
        await getAccessTokenSilently(),
        application.application_id,
        name,
        baseAddress,
        multiTenancy,
        description,
        {
          request: requestHook,
          create: createHook,
          update: updateHook,
          destroy: destroyHook,
        },
        settings,
        dependencies,
        documentationLink,
        supportLink,
        videoLink,
        logoLink,
        tags
      )
        .then((response) => {
          setApplication(response.data);
          setEditMode(false);
          setWaiting(false);
        })
        .catch((error) => {
          setError(error);
          setEditMode(false);
          setWaiting(false);
        });
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getApp();
    } else {
      setApplication();
    }
  }, [getApp, isAuthenticated, getAccessTokenSilently, setApplication]);

  useEffect(() => {
    initalizeFields();
  }, [initalizeFields, application, editMode]);

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'baseAddress':
        setBaseAddress(event.target.value);
        break;
      //mutlitenancy is skipped here purposefully as it should not be updated
      case 'description':
        setDescription(event.target.value);
        break;
      case 'documentationLink':
        setDocumentationLink(event.target.value);
        break;
      case 'videoLink':
        setVideoLink(event.target.value);
        break;
      case 'logoLink':
        setLogoLink(event.target.value);
        break;
      case 'supportLink':
        setSupportLink(event.target.value);
        break;
      case 'requestHook':
        event.target.value === ''
          ? setRequestHook(undefined)
          : setRequestHook(event.target.value);
        break;
      case 'createHook':
        event.target.value === ''
          ? setCreateHook(undefined)
          : setCreateHook(event.target.value);
        break;
      case 'updateHook':
        event.target.value === ''
          ? setUpdateHook(undefined)
          : setUpdateHook(event.target.value);
        break;
      case 'destroyHook':
        event.target.value === ''
          ? setDestroyHook(undefined)
          : setDestroyHook(event.target.value);
        break;
      default:
        break;
    }
  }

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (name === '') {
      setNameError('Please enter a name for your application.');
      result = false;
    } else {
      setNameError();
    }
    if (logoLink && !logoLink.match(validURlregex)) {
      setLogoLinkError('Please enter a valid URL to your logo');
      result = false;
    } else {
      setLogoLinkError();
    }
    if (documentationLink && !documentationLink.match(validURlregex)) {
      setDocumentationLinkError(
        'Please enter a valid URL to your documentation'
      );
      result = false;
    } else {
      setDocumentationLinkError();
    }
    if (videoLink && !videoLink.match(validURlregex)) {
      setVideoLinkError('Please enter a valid URL to your video');
      result = false;
    } else {
      setVideoLinkError();
    }
    if (supportLink && !supportLink.match(validURlregex)) {
      setSupportLinkError('Please enter a valid URL to your documentation');
      result = false;
    } else {
      setSupportLinkError();
    }
    if (!baseAddress.match(validURlregex)) {
      setBaseAddressError('Please enter the full URL of your application.');
      result = false;
    } else {
      setBaseAddressError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setRequestHookError();
    }
    if (createHook && !createHook.match(validURlregex)) {
      setCreateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setCreateHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setUpdateHookError();
    }
    if (destroyHook && !destroyHook.match(validURlregex)) {
      setDestroyHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setDestroyHookError();
    }
    return result;
  }

  async function requestDeleteApplication() {
    setWaiting(true);
    deleteApplication(
      await getAccessTokenSilently(),
      application.application_id
    )
      .then((response) => {
        navigate('/');
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Container className="appComponent">
      <NavigationButton destination="/" />
      <Segment>
        {application ? (
          <Container>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={12}>
                  <Header className="contentHeader">
                    Application: {application.name}
                  </Header>
                  <CollaborationModal
                    entity={application}
                    entityType="application"
                    trigger={
                      <CollaboratorList
                        entity={application}
                        includeManage={flags.collaboration?.enabled}
                      />
                    }
                    function_reloaddata={getApp}
                  ></CollaborationModal>
                </Grid.Column>
                <Grid.Column width={4}>
                  {editMode ? (
                    <span>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded basic"
                        type="reset"
                        onClick={() => {
                          setEditMode(false);
                          getApp();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded"
                        type="submit"
                        onClick={() => updateApp()}
                      >
                        Update
                      </Button>
                    </span>
                  ) : (
                    <span>
                      <Button
                        floated="right"
                        disabled={waiting}
                        type="button"
                        color="red"
                        onClick={() => {
                          requestDeleteApplication();
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        floated="right"
                        disabled={waiting}
                        className="branded"
                        type="button"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        Edit
                      </Button>
                    </span>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header.Subheader className="meta">
              <MetaData meta_data={application.meta_data} />
            </Header.Subheader>

            <Divider />
          </Container>
        ) : (
          <Header className="contentHeader">Application:</Header>
        )}
        {error ? <ErrorMessage error={error} /> : null}

        <Container>
          {application ? (
            <Container>
              <Form loading={waiting}>
                <FormField>
                  <label>Category</label>
                  <CategoryLabel category={application.category} />
                  {flagsmith.getTrait('connection') === 'employee' &&
                    application.category === 'private' && (
                      <a
                        className="padded"
                        href="https://form.asana.com/?k=PT8Qfq-ZNf9n-H9CuXrRFA&d=10114792544943"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Share to Community
                      </a>
                    )}
                </FormField>
                <Form.Field
                  id="componentID"
                  control={Input}
                  label="Component ID"
                  value={application.application_id}
                  readOnly
                />
                <Form.Field
                  id="name"
                  control={Input}
                  label="Name"
                  value={name}
                  onChange={handleChange}
                  error={nameError}
                  onBlur={validateForm}
                  maxLength="30"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="description"
                  value={description}
                  onChange={handleChange}
                  label="Description"
                  control="textarea"
                  rows="3"
                  maxLength="1024"
                  placeholder="A custom demo."
                  readOnly={!editMode}
                />
                <Form.Field
                  id="logoLink"
                  value={logoLink}
                  onChange={handleChange}
                  error={logoLinkError}
                  onBlur={validateForm}
                  label="Logo Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="documentationLink"
                  value={documentationLink}
                  onChange={handleChange}
                  error={documentationLinkError}
                  onBlur={validateForm}
                  label="Documentation Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="videoLink"
                  value={videoLink}
                  onChange={handleChange}
                  error={videoLinkError}
                  onBlur={validateForm}
                  label="Video Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />
                <Form.Field
                  id="supportLink"
                  value={supportLink}
                  onChange={handleChange}
                  error={supportLinkError}
                  onBlur={validateForm}
                  label="Support Link"
                  control={Input}
                  maxLength="1024"
                  readOnly={!editMode}
                />

                <Header as="h3">Application Configuration</Header>

                <Form.Field
                  id="baseAddress"
                  control={Input}
                  label="Base Address"
                  value={baseAddress}
                  onChange={handleChange}
                  placeholder="https://example.com"
                  error={baseAddressError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />

                <Form.Dropdown
                  label="Demonstration discovery method"
                  name="multiTenancy"
                  options={DemonstrationDiscoveryOptions}
                  value={multiTenancy}
                  selection
                  disabled
                />

                <Dependencies
                  dependencies={dependencies}
                  onChange={setDependencies}
                  allowEdit={editMode}
                />

                <Header as="h3">Settings</Header>
                <ComponentSettings
                  settings={settings}
                  allowEdit={editMode}
                  setSettings={setSettings}
                />

                <Header as="h3">Lifecycle Hooks</Header>
                <Form.Field
                  id="requestHook"
                  value={requestHook}
                  onChange={handleChange}
                  label="Requested Event"
                  control={Input}
                  placeholder="https://example.com/requested"
                  error={requestHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="createHook"
                  value={createHook}
                  onChange={handleChange}
                  label="Created Event"
                  control={Input}
                  placeholder="https://example.com/created"
                  error={createHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="updateHook"
                  value={updateHook}
                  onChange={handleChange}
                  label="Updated Event"
                  control={Input}
                  placeholder="https://example.com/updated"
                  error={updateHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
                <Form.Field
                  id="destroyHook"
                  value={destroyHook}
                  onChange={handleChange}
                  label="Deleted Event"
                  control={Input}
                  placeholder="https://example.com/deleted"
                  error={destroyHookError}
                  onBlur={validateForm}
                  readOnly={!editMode}
                />
              </Form>

              <Divider />

              {application.oidc_configuration ? (
                <Container>
                  <Header as="h3">Demo API Credentials</Header>
                  <Form>
                    <FormField
                      label="Application Id"
                      control={Input}
                      value={application.application_id}
                      readOnly
                      icon={
                        <Icon
                          name="clone outline"
                          link
                          onClick={() =>
                            navigator.clipboard.writeText(
                              application.application_id
                            )
                          }
                        />
                      }
                    />
                    <FormField
                      label="Token URL"
                      control={Input}
                      value={application.oidc_configuration.tokenUrl}
                      readOnly
                      icon={
                        <Icon
                          name="clone outline"
                          link
                          onClick={() =>
                            navigator.clipboard.writeText(
                              application.oidc_configuration.tokenUrl
                            )
                          }
                        />
                      }
                    />
                    <FormField
                      label="Client Id"
                      control={Input}
                      value={application.oidc_configuration.client_id}
                      readOnly
                      icon={
                        <Icon
                          name="clone outline"
                          link
                          onClick={() =>
                            navigator.clipboard.writeText(
                              application.oidc_configuration.client_id
                            )
                          }
                        />
                      }
                    />
                    <FormField
                      label="Client Secret"
                      control={Input}
                      value={application.oidc_configuration.client_secret}
                      type="password"
                      readOnly
                      icon={
                        <Icon
                          name="clone outline"
                          link
                          onClick={() =>
                            navigator.clipboard.writeText(
                              application.oidc_configuration.client_secret
                            )
                          }
                        />
                      }
                    />
                  </Form>
                </Container>
              ) : null}
            </Container>
          ) : (
            <Loader active inline />
          )}
        </Container>
      </Segment>
    </Container>
  );
};
export default ApplicationDetails;
