import { useState, useEffect } from 'react';
import { Container, Header, Button, Segment, Divider } from 'semantic-ui-react';
import flagsmith from 'flagsmith';

const Oktaprise = ({ step, func_complete }) => {
  const [waiting, setWaiting] = useState(false);

  async function submit() {
    setWaiting(true);
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete();
  }

  useEffect(() => {
    if (flagsmith.getTrait('connection') !== 'employee') {
      func_complete();
    }
  }, [func_complete]);

  return (
    <Container>
      <Container>
        <Segment basic>
          <Header>Oktaprise</Header>
          <Container>
            <p>
              The power of Okta's integration network is unbounded; with
              integrations that enhance security, productivity, and beyond.
              Showcasing these integrations is core to Okta's value, however
              some can be complex to setup independently. Configuring SSO with
              the Oktaprise shared demonstration environment will enable you to
              be able to include demonstrations of our technology partners; all
              from within your tenant; with little or no configuration required.
            </p>
          </Container>
          <Divider hidden />
          <Container>
            <Button disabled={waiting} className="branded" onClick={submit}>
              Connect
            </Button>
            <Button disabled={waiting} className="" onClick={skip}>
              Skip
            </Button>
          </Container>
        </Segment>
      </Container>
    </Container>
  );
};
export default Oktaprise;
