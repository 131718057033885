import React, { useState } from 'react';
import { Button, Header, Icon, Input } from 'semantic-ui-react';
import { setDemonstrationLabel } from '../services/DemoAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { useDemoContext } from '../DemoContext';

export const DemonstrationLabel = ({ demo }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [waiting, setWaiting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [demoLabel, setDemoLabel] = useState(demo.label);
  const demoContext = useDemoContext();

  function handleChange(event) {
    setDemoLabel(event.target.value);
  }

  return (
    <Header className="contentHeader">
      {!editMode ? (
        <>
          <span>
            {demoLabel}{' '}
            <Icon
              className="inlineIconControl"
              name="edit"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </span>
          {demoLabel !== demo.name && <Header sub>{demo.name}</Header>}
        </>
      ) : (
        <>
          <Input
            disabled={waiting}
            name="demoLabel"
            className="inlineInput"
            value={demoLabel}
            onChange={handleChange}
          >
            <input maxLength="64" />
          </Input>
          <Button
            disabled={waiting}
            className="branded"
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={async () => {
              setWaiting(true);
              await setDemonstrationLabel(
                await getAccessTokenSilently(),
                demo.name,
                demoLabel
              );
              demoContext.refreshContext();
              setEditMode(false);
              setWaiting(false);
            }}
          >
            Update
          </Button>
          <Button
            disabled={waiting}
            style={{ fontSize: '1rem', verticalAlign: 'text-top' }}
            onClick={() => {
              setDemoLabel(demo.label);
              setEditMode(false);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Header>
  );
};
