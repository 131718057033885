import { Button, Container, Form, Loader } from 'semantic-ui-react';
import InstanceLabel from './InstanceLabel';
import InstanceSettings from './InstanceSettings';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import Config from '../../Config';
import axios from 'axios';
import ErrorMessage from '../ErrorMessage';
import { sortObjectByKeys } from '../../util/SortObjectByKeys';
import { hasUserFacingSetting } from '../../util/Settings';

const InstanceConfiguration = ({ instance, type, isWaiting, onUpdate }) => {
  let params = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [componentInstance, setComponentInstance] = useState(instance);
  const [waiting, setWaiting] = useState(isWaiting);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [label, setLabel] = useState(instance.label);
  const [settings, setSettings] = useState();

  function handleLabelChange(value) {
    setLabel(value);
  }

  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.settings)));
  }, [instance.settings]);

  useEffect(() => {
    var outcome = false;
    if (label !== componentInstance.label) {
      outcome = true;
    }
    if (!outcome && componentInstance.settings && settings) {
      if (
        JSON.stringify(sortObjectByKeys(componentInstance.settings)) !==
        JSON.stringify(sortObjectByKeys(settings))
      ) {
        outcome = true;
      }
    }
    setIsChanged(outcome);
  }, [settings, label, componentInstance.label, componentInstance.settings]);

  function handleSettingChange(event, { name, value }) {
    var settingKey = name.replace('settings.', '');
    var updateSettings = settings;
    if (value.length > 0 || typeof value === 'boolean') {
      updateSettings[settingKey].instance = value;
    } else {
      delete updateSettings[settingKey].instance;
    }
    setSettings({ ...updateSettings });
  }

  function validateForm() {
    var result = true;
    if (label === '') {
      setError('Please enter a valid alias for your instance.');
      result = false;
    } else {
      setError();
    }
    return result;
  }

  function setDefault(key) {
    var updateSettings = settings;
    delete updateSettings[key].instance;
    setSettings({ ...settings });
  }

  async function updateApp() {
    if (validateForm()) {
      setWaiting(true);

      var instanceSettings = {};
      Object.entries(settings).forEach(([key, value]) => {
        if (value.hasOwnProperty('instance')) {
          instanceSettings[key] = value.instance;
        }
      });

      var data = {
        label,
        settings: instanceSettings,
      };
      const url =
        type === 'application'
          ? `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/apps/${params.appId}`
          : `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/resources/${params.resourceId}`;

      axios
        .put(url, data, {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        })
        .then((response) => {
          setComponentInstance(response.data);
          setLabel(response.data.label);
          setSettings(JSON.parse(JSON.stringify(response.data.settings)));
          onUpdate(response.data.label);
          setWaiting(false);
          setIsChanged(false);
        })
        .catch((error) => {
          setError(error);
          setWaiting(false);
        });
    }
  }

  return (
    <Container>
      {error ? <ErrorMessage error={error} /> : null}
      <Form loading={isWaiting}>
        <InstanceLabel label={label} onChange={handleLabelChange} />
        {settings && hasUserFacingSetting(settings) ? (
          <InstanceSettings
            settings={settings}
            onChange={handleSettingChange}
            onRevertToDefault={setDefault}
          />
        ) : null}
        <Container textAlign="right">
          {waiting && <Loader active inline />}
          <Button
            disabled={waiting || !isChanged}
            className="branded"
            type="submit"
            onClick={() => updateApp()}
          >
            Update
          </Button>
        </Container>
      </Form>
    </Container>
  );
};

export default InstanceConfiguration;
