import { Button } from 'semantic-ui-react';
import { useUserPreferenceContext } from '../../UserPreferenceContext';

const LaunchButton = ({
  url,
  label = 'Launch',
  className = 'branded',
  icon = 'external alternate',
}) => {
  const userPreference = useUserPreferenceContext();

  return (
    <Button
      icon={icon}
      className={className}
      onClick={() => window.open(userPreference.rewriteLaunchUrl(url))}
      content={label}
    />
  );
};

export default LaunchButton;
