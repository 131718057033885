import { Button, Container, Icon, List, Message } from 'semantic-ui-react';

const ErrorMessage = ({ error, retryAction }) => {
  const validationErrors = error.response?.data?.validationErrors;

  function displayValidationError(error) {
    return <List.Item>{error.message}</List.Item>;
  }

  let displayMsg = error;
  if (error.response?.data?.message) {
    displayMsg = error.response?.data?.message;
  } else if (error.message) {
    displayMsg = error.message;
  }
  return (
    <Message negative icon>
      <Icon name="warning" />
      <Message.Content>
        <Message.Header>There was a problem with your request</Message.Header>
        <p>{displayMsg}</p>
        {validationErrors?.length > 0 && (
          <Container className="scrollable">
            <List bulleted>
              {validationErrors.map((error) => displayValidationError(error))}
            </List>
          </Container>
        )}
        <p>
          If the problem persists please reach out to the Demo Engineering team.
        </p>
        {retryAction ? (
          <Button className="brandColor" compact onClick={retryAction}>
            <Icon link name="sync"></Icon>Retry
          </Button>
        ) : null}
      </Message.Content>
    </Message>
  );
};
export default ErrorMessage;
