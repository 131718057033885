const DeploymentLogView = ({ deploymentLogs }) => {
  const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short',
  };

  return (
    <pre className="pre-line-text">
      {deploymentLogs.map((event, i) => (
        <div key={i}>
          [
          {new Intl.DateTimeFormat(undefined, dateOptions).format(
            new Date(event.timestamp)
          )}
          ] {event.latestMessage}
        </div>
      ))}
    </pre>
  );
};

export default DeploymentLogView;
