import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Header,
  Form,
  Popup,
  Loader,
  Button,
  Segment,
  Input,
  Divider,
  Icon,
  Message,
} from 'semantic-ui-react';
import {
  createIDP,
  createDemonstration,
  getDemonstration,
  attachAppToDemonstration,
  linkDemonstrationToOpp,
  attachResourceToDemonstration,
  getIDP,
} from '../services/DemoAPI';
import OpportunityPicker from '../components/OpportunityPicker';
import { useOpportunityContext } from '../OpportunityContext';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import NavigationButton from '../components/NavigationButton';
import { generateDemoName } from '../util/GenerateDemoNames';
import BrandingPrompt from '../components/template/BrandingPrompt';
import ADPrompt from '../components/template/ADPrompts';
import { useDemoContext } from '../DemoContext';
import { useInterval } from '../useInterval';
import Oktaprise from '../components/template/Oktaprise';
import Productivity from '../components/template/ProductivityPrompt';
import StorytimePrompt from '../components/template/StorytimePrompt';
import FGAPrompt from '../components/template/FGAPrompt';

const TemplateDeploy = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const demoContext = useDemoContext();
  const { opportunities } = useOpportunityContext();
  const flags = useFlags([
    'one_click_wic',
    'one_click_cic',
    'fga_smarthub',
    'crowdstrike',
    'pitchbook_software_rationalization',
    'opportunity_linking',
    'oig_enablement',
    'itp_enablement',
  ]);
  const flagsmithState = useFlagsmithLoading();

  const [idpPollFrequency, setIdpPollFrequency] = useState(null);
  const [demoPollFrequency, setDemoPollFrequency] = useState(null);

  const [stage, setStage] = useState('invite');
  const [steps, setSteps] = useState([]);
  const [stepsTaken, setStepsTaken] = useState([]);

  const [oppLinked, setOppLinked] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  const [template, setTemplate] = useState({});
  const [name, setName] = useState(generateDemoName());
  const [nameError, setNameError] = useState();
  const [demo, setDemo] = useState();
  const [idp, setIdp] = useState();

  useEffect(() => {
    if (
      isAuthenticated &&
      !flagsmithState.isLoading &&
      !flagsmithState.isFetching
    ) {
      if (!params.template) {
        navigate('/');
        return;
      }
      var templateName = params.template.replaceAll('-', '_');
      var templateConfig = flags[templateName];
      if (!templateConfig || !templateConfig.enabled || !templateConfig.value) {
        navigate('/');
      } else {
        const template = JSON.parse(templateConfig.value);
        setTemplate(template);
      }
    }
  }, [
    navigate,
    isAuthenticated,
    flags,
    params.template,
    flagsmithState.isLoading,
    flagsmithState.isFetching,
  ]);

  function navigateToDemo() {
    navigate('/demo/' + name, {
      replace: true,
      state: { demo: demo, idp: idp },
    });
  }

  async function completeAction(action) {
    var newSteps = steps;
    var resolved = newSteps.shift();
    if (resolved === undefined) {
      newSteps = [];
    }
    if (action?.steps) {
      for (let index = action.steps.length - 1; index >= 0; index--) {
        const element = action.steps[index];
        newSteps.unshift(element);
      }
    }

    if (action) {
      setStage('deploy');
      switch (action.type) {
        case 'application':
          await attachAppToDemonstration(
            await getAccessTokenSilently(),
            name,
            action.id,
            undefined,
            action.settings
          );
          break;
        case 'resource':
          await attachResourceToDemonstration(
            await getAccessTokenSilently(),
            name,
            action.id,
            undefined,
            action.settings
          );
          break;
        default:
          console.error('Unhandled template object type');
      }
      var completedSteps = stepsTaken;
      completedSteps.push(action);
      setStepsTaken(completedSteps);
    }
    setDemoPollFrequency(2000);
    setSteps(newSteps);
  }

  function canProgress() {
    var requiredStepsComplete = true;
    stepsTaken.forEach((element) => {
      var step;
      switch (element.type) {
        case 'application': {
          step = demo?.app_instances?.find(
            (item) => item.applicationId === element.id
          );
          break;
        }
        case 'resource': {
          step = demo?.resource_instances?.find(
            (item) => item.resourceId === element.id
          );
          break;
        }
        default: {
          console.error('Unknown required object type: ' + element.type);
        }
      }
      if (step === undefined || (element.await && step.state !== 'active')) {
        requiredStepsComplete = false;
      }
    });
    return requiredStepsComplete;
  }

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      default:
        break;
    }
  }

  function validateForm() {
    let result = true;

    //test alphanumeric and hyphen, start & end cannot be hyphen
    const tenantPattern = '^(?!W|-)((?!admin|okta)[a-z-0-9]){3,63}$';
    const tenantRegex = new RegExp(`^${tenantPattern}$`);

    if (
      !name ||
      name.length < 3 ||
      name.length > 63 ||
      !tenantRegex.test(name)
    ) {
      setNameError(
        "Name must be between 3 and 63 characters and include only alphanumeric and hyphen. This must not contain the words 'admin' or 'okta'."
      );
      result = false;
    } else {
      setNameError();
    }
    return result;
  }

  async function linkOpp(event) {
    if (event.target.value !== 'noop') {
      linkDemonstrationToOpp(
        await getAccessTokenSilently(),
        name,
        event.target.value
      );
    }
    setOppLinked(true);
  }

  async function submitForm() {
    if (validateForm()) {
      setStage('provision');
      setDisplayMessage('Creating your demo.');
      const at = await getAccessTokenSilently();
      try {
        var idp = await createIDP(
          at,
          name,
          template.idpType,
          template.idpVariant
        );
        setIdp(idp.data);
        setIdpPollFrequency(2000);
        var demo = await createDemonstration(
          at,
          name,
          'opportunity',
          idp.data.idp_id,
          template.label,
          template.name
        );
        demoContext.refreshContext();
        setDemo(demo.data);
        setDemoPollFrequency(2000);
        setSteps(template.steps);
      } catch (err) {
        setStage('error');
      }
    }
  }

  useInterval(async () => {
    if (idp) {
      var response = await getIDP(await getAccessTokenSilently(), idp.idp_id);
      setIdp(response.data);
    }
  }, idpPollFrequency);

  useInterval(async () => {
    if (demo) {
      var response = await getDemonstration(
        await getAccessTokenSilently(),
        name
      );
      setDemo(response.data);
      if (idp.state === 'active') {
        if (response.data.state === 'error') {
          setStage('error');
        }
        var progress = canProgress();
        if (progress && steps?.length > 0) {
          if (!steps[0].prompt) {
            completeAction(steps[0]);
          } else {
            setStage('step');
            setDemoPollFrequency(null);
          }
        } else if (steps?.length === 0 && progress) {
          navigateToDemo();
        }
      }
    }
  }, demoPollFrequency);

  //Monitor for IDP in ready state
  useEffect(() => {
    switch (idp?.state) {
      case 'error':
        setDisplayMessage('Waiting for Identity Cloud to be ready...');
        break;
      case 'queued':
        setDisplayMessage('Waiting in queue...');
        break;
      case 'deploying':
        setDisplayMessage('Launching Identity Cloud...');
        break;
      case 'active':
        setIdpPollFrequency(null);
        break;
      default:
        break;
    }
  }, [idp?.state, getAccessTokenSilently]);

  function renderOppComponent() {
    return (
      <Container>
        {!oppLinked &&
          flags.opportunity_linking.enabled &&
          opportunities.length > 0 && (
            <Container>
              <Segment basic>
                <Header>Link your opportunity</Header>
                <OpportunityPicker
                  allowEdit={true}
                  updateOpportunity={linkOpp}
                />
              </Segment>
            </Container>
          )}
      </Container>
    );
  }

  function renderStep() {
    if (steps && steps.length >= 1) {
      if (steps[0].prompt) {
        switch (steps[0].prompt) {
          case 'branding':
            return (
              <BrandingPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'ad':
            return (
              <ADPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'oktaprise':
            return (
              <Oktaprise
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'productivity':
            return (
              <Productivity
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'storytime':
            return (
              <StorytimePrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          case 'fga':
            return (
              <FGAPrompt
                step={steps[0]}
                demoName={name}
                func_complete={completeAction}
              />
            );
          default:
            console.error('Unhandled prompt.');
            completeAction();
        }
      }
    }
  }

  function renderStage(stage) {
    switch (stage) {
      case 'invite':
        return (
          <Container>
            <Header className="contentHeader">Template: {template.name}</Header>
            <Divider className="noTopMargin" />
            <Container className="pre-line-text">
              {template.description}
            </Container>
            <Divider />
            <Form>
              <Form.Field
                id="name"
                icon={{
                  name: 'refresh',
                  link: true,
                  onClick: () => {
                    setName(generateDemoName());
                  },
                }}
                label={
                  <label>
                    Demonstration name{' '}
                    <Popup
                      content={
                        'Enter a unique name for your demonstration. ' +
                        'This name will be visible in the identity provider tenant as well as some applications. '
                      }
                      trigger={
                        <span>
                          <Icon name="info circle" />
                        </span>
                      }
                    />
                  </label>
                }
                control={Input}
                value={name}
                onChange={handleChange}
                error={nameError}
                onKeyUp={validateForm}
                style={{ textTransform: 'lowercase' }}
                required
              />
              <Button className="branded" type="submit" onClick={submitForm}>
                Create
              </Button>
            </Form>
          </Container>
        );
      case 'provision':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <Loader active inline indeterminate content={displayMessage} />
            </Container>
            {renderOppComponent()}
          </Container>
        );
      case 'step':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            {renderOppComponent()}
            {renderStep()}
          </Container>
        );
      case 'deploy':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <Loader
                active
                inline
                indeterminate
                content={'Deploying template...'}
              />
            </Container>
            {renderOppComponent()}
          </Container>
        );
      case 'error':
        return (
          <Container>
            <Header className="contentHeader">Demonstration: {name}</Header>
            <Divider className="noTopMargin" />
            <Container textAlign="center">
              <Message negative icon>
                <Icon name="warning" />
                <Message.Content>
                  <Message.Header>
                    Something went wrong deploying template
                  </Message.Header>
                  <p>
                    A component encountered an error whilst deploying your
                    template and deployment has stopped. Please view the logs
                    for details.
                  </p>
                  <p>
                    If the problem persists please reach out to the Demo
                    Engineering team.
                  </p>
                  <Divider hidden />
                  <Button
                    className="branded"
                    type="submit"
                    onClick={() => navigateToDemo()}
                  >
                    Continue to demo
                  </Button>
                </Message.Content>
              </Message>
            </Container>
          </Container>
        );
      default:
    }
    return;
  }

  if (flagsmithState.isLoading && !flagsmithState.isFetching) {
    return (
      <Container className="appComponent">
        <Loader active inline />
      </Container>
    );
  }
  return (
    <Container className="appComponent">
      <NavigationButton destination="/" msg="Show my demos" />
      <Segment>{renderStage(stage)}</Segment>
    </Container>
  );
};

export default TemplateDeploy;
