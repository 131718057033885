import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import {
  Container,
  Header,
  Loader,
  Segment,
  Grid,
  Button,
} from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import NavigationButton from '../components/NavigationButton';
import InstanceDetails from '../components/instance/InstanceDetails';
import { sortObjectByKeys } from '../util/SortObjectByKeys';
import { BreadcrumbList, Callout } from '@okta/odyssey-react-mui';
import { detachResourceFromDemonstration } from '../services/DemoAPI';
import { useNavigate } from 'react-router';

const ResourceInstanceDetails = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [resource, setResource] = useState(null);
  const [label, setLabel] = useState();
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getResource = useCallback(async () => {
    setResource();
    setLabel();
    axios
      .get(
        `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/resources/${params.resourceId}`,
        {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        }
      )
      .then((response) => {
        response.data.settings = sortObjectByKeys(response.data.settings);
        setResource(response.data);
        setLabel(response.data.label);
      })
      .catch((error) => {
        setError(error);
      });
  }, [
    setResource,
    setLabel,
    getAccessTokenSilently,
    params.resourceId,
    params.demoName,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getResource();
    } else {
      setResource();
      setLabel();
    }
  }, [getResource, isAuthenticated, getAccessTokenSilently, setResource]);

  async function detachInstance() {
    setWaiting(true);
    detachResourceFromDemonstration(
      await getAccessTokenSilently(),
      params.demoName,
      params.resourceId
    )
      .then(() => {
        navigate('/demo/' + params.demoName);
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Container className="appComponent">
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <NavigationButton
          destination={`/demo/${params.demoName}`}
          msg={params.demoName}
        />
        <i href="">{resource ? resource.label : params.resourceId}</i>
      </BreadcrumbList>
      <Segment>
        {resource ? (
          <Container>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={13}>
                  <Header className="contentHeader">{label}</Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  {waiting ? <Loader active inline /> : null}
                  <Button
                    floated="right"
                    disabled={waiting}
                    type="button"
                    onClick={() => detachInstance()}
                  >
                    Detach
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {resource.deployedVersion < resource.latestVersion && (
              <Callout
                severity="info"
                title="New version available"
                text="An updated version of this resource is available, features and behaviour of this resource may differ based on your deployed version. To update to the latest version detach and reattach this resource."
              />
            )}
            <Header.Subheader className="meta">
              <CategoryLabel category={resource.category} />
              {resource ? (
                <InstanceDetails
                  instance={resource}
                  type="resource"
                  onUpdate={setLabel}
                  onError={setError}
                />
              ) : (
                <Loader active inline />
              )}
            </Header.Subheader>
          </Container>
        ) : (
          <Header className="contentHeader">
            {params.demoName}: Resource Instance
          </Header>
        )}
        {error ? <ErrorMessage error={error} /> : null}
      </Segment>
    </Container>
  );
};
export default ResourceInstanceDetails;
