export function getTemplateSettings(template) {
  var verticalTemplate = StorytimeTemplates.find((i) => i.name === template);
  if (verticalTemplate) {
    return verticalTemplate.settings;
  } else {
    return {};
  }
}

export const StorytimeTemplates = [
  {
    name: 'finserv',
    displayName: 'Financial Services',
    settings: {
      brand_background: '#f7f7f7',
      'brand_background-contrast': 'black',
      brand_color: '#0e3623',
      'brand_color-contrast': '#f7f7f7',
      brand_logo:
        'https://cdn.demo.okta.com/images/storytime/finserv/finserv_logo.png',
      brand_name: 'Narrative Investments',
      'debug_accesstoken-image':
        'https://cdn.demo.okta.com/images/storytime/finserv/debug_access_img.jpg',
      'debug_idtoken-image':
        'https://cdn.demo.okta.com/images/storytime/finserv/debug_profile_img.jpg',
      landing_footer_copy:
        'Helping you build for the future with investments powered by your identity.',
      landing_heroImage:
        'https://cdn.demo.okta.com/images/storytime/finserv/hero_image.jpg',
      landing_heroMessage: 'Invest in the future',
      landing_useHeroMessage: true,
      profile_image:
        'https://cdn.demo.okta.com/images/storytime/finserv/profile_img.jpg',
      'protected_empty-message': 'Nothing invested here yet.',
      protected_image:
        'https://cdn.demo.okta.com/images/storytime/finserv/protected_img.jpg',
      protected_prompt: 'Add an investment',
      protected_title: 'Your Investments',
    },
  },
  {
    name: 'health',
    displayName: 'Healthcare',
    settings: {
      brand_background: '#ffffff',
      'brand_background-contrast': 'black',
      brand_color: '#014380',
      'brand_color-contrast': '#F7F7F7',
      brand_logo:
        'https://cdn.demo.okta.com/images/storytime/healthcare/health_logo.png',
      brand_name: 'Chronicle Health',
      'debug_accesstoken-image':
        'https://cdn.demo.okta.com/images/storytime/healthcare/debug_access_img.jpg',
      'debug_idtoken-image':
        'https://cdn.demo.okta.com/images/storytime/healthcare/debug_profile_img.jpg',
      landing_footer_copy:
        'Our team of experienced and compassionate healthcare professionals strives to provide exceptional medical services to improve your overall health and well-being.',
      landing_heroImage:
        'https://cdn.demo.okta.com/images/storytime/healthcare/hero_image.jpg',
      landing_heroMessage: 'For you, for your family',
      landing_useHeroMessage: true,
      profile_image:
        'https://cdn.demo.okta.com/images/storytime/healthcare/profile_img.jpg',
      'protected_empty-message': 'No care records.',
      protected_image:
        'https://cdn.demo.okta.com/images/storytime/healthcare/protected_img.jpg',
      protected_prompt: 'Add a care record',
      protected_title: 'Your Care',
    },
  },
  {
    name: 'saas',
    displayName: 'SaaS',
    settings: {
      brand_background: '#F6F1E7',
      'brand_background-contrast': '#534157',
      brand_color: '#534157',
      'brand_color-contrast': 'white',
      brand_logo:
        'https://cdn.demo.okta.com/images/storytime/saas/saas_logo.png',
      brand_name: 'PlotCloud',
      'debug_accesstoken-image':
        'https://cdn.demo.okta.com/images/storytime/saas/debug_access_img.jpg',
      'debug_idtoken-image':
        'https://cdn.demo.okta.com/images/storytime/saas/debug_profile_img.jpg',
      landing_footer_copy:
        'Your all-in-one platform for crafting compelling stories. From outlining to publishing, our SaaS tool empowers writers to bring their ideas to life.',
      landing_heroImage:
        'https://cdn.demo.okta.com/images/storytime/saas/hero_image.jpg',
      landing_useHeroMessage: false,
      'landing_stats-image':
        'https://cdn.demo.okta.com/images/storytime/saas/landing_image.jpg',
      profile_image:
        'https://cdn.demo.okta.com/images/storytime/saas/profile_img.jpg',
      'protected_empty-message': "Your blank page...let's get started!",
      protected_image:
        'https://cdn.demo.okta.com/images/storytime/saas/protected_img.jpg',
      protected_prompt: 'Begin a new story!',
      protected_title: 'Your Plots',
      feature_organizations: true,
      org_image: 'https://cdn.demo.okta.com/images/storytime/saas/org_img.jpg',
      'org_signup-action': 'Join PlotCloud',
      'org_signup-name-prompt': 'Your team name',
      'org_signup-title': 'Work with your team in PlotCloud',
    },
  },
];
