import flagsmith from 'flagsmith';
import { createContext, useContext } from 'react';

const UserPreferenceContext = createContext({ rewriteLaunchUrl: () => {} });

const UserPreferenceContextProvider = ({ children }) => {
  const rdpPattern =
    /(?<protocol>rdp:\/\/)(full%20address:s:)(?<ip>[0-9.:]+)(&.*)/;

  function rewriteLaunchUrl(url) {
    if (flagsmith.getTrait('rdp_preference') === 'tsx') {
      var isRDP = rdpPattern.exec(url);
      if (isRDP) {
        return 'rtsx://' + isRDP.groups['protocol'] + isRDP.groups['ip'];
      }
    }
    return url;
  }

  return (
    <UserPreferenceContext.Provider value={{ rewriteLaunchUrl }}>
      {children}
    </UserPreferenceContext.Provider>
  );
};
export const useUserPreferenceContext = () => useContext(UserPreferenceContext);
export default UserPreferenceContextProvider;
