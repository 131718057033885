import { Icon } from 'semantic-ui-react';

const StateLabel = ({ state }) => {
  switch (state) {
    case 'active':
      return (
        <div>
          <Icon color="green" name="check circle" /> Active
        </div>
      );
    case 'deploying':
      return (
        <div>
          <Icon name="hourglass two" /> Deploying
        </div>
      );
    case 'queued':
      return (
        <div>
          <Icon loading name="wait" /> Queued
        </div>
      );
    case 'error':
      return (
        <div>
          <Icon color="red" name="dont" /> Error
        </div>
      );
    default:
      return (
        <div>
          <Icon name="question circle" /> {state}
        </div>
      );
  }
};
export default StateLabel;
