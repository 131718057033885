import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './components/Globalstyle';
import { lightTheme, darkTheme } from './components/Themes';
import { useTheme } from './components/ThemeController';
import Nav from './components/nav';
import Home from './routes/Home';
import DemoDetails from './routes/DemoDetails';
import ApplicationDetails from './routes/ApplicationDetails';
import AppInstanceDetails from './routes/AppInstanceDetails';
import CreateApplication from './routes/CreateApplication';
import CreateDemo from './routes/CreateDemo';
import DemoApps from './routes/DemoApps';
import DemoResources from './routes/DemoResources';
import ResourceDetails from './routes/ResourceDetails';
import ResourceInstanceDetails from './routes/ResourceInstanceDetails';
import CreateResource from './routes/CreateResource';
import flagsmith from 'flagsmith';
import DemoContextProvider from './DemoContext';
import OpportunityContextProvider from './OpportunityContext';
import LibraryDemonstrationCreate from './routes/LibraryDemonstrationCreate';
import Settings from './routes/Settings';
import TemplateDeploy from './routes/TemplateDeploy';
import UserPreferenceContextProvider from './UserPreferenceContext';
import Heropa from './components/Heropa';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, user } =
    useAuth0();

  const [theme] = useTheme();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (isLoading) {
    return (
      <Dimmer blurring="true" inverted active id="innerDimmer">
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }
  if (error) {
    window.location.replace(
      `${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENT_ID}&error=${error.name}&error_description=${error.message}`
    );
    return null;
  }
  if (isAuthenticated) {
    flagsmith.identify(user.email, {
      connection:
        user['https://' + process.env.REACT_APP_DOMAIN + '/connection/'],
    });
    return (
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <Nav></Nav>
        <UserPreferenceContextProvider>
          <OpportunityContextProvider>
            <DemoContextProvider>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/settings" exact element={<Settings />} />
                <Route path="/demo" exact element={<CreateDemo />} />
                <Route path="/demo/:demoName" element={<DemoDetails />} />
                <Route path="/demo/:demoName/apps" element={<DemoApps />} />
                <Route
                  path="/demo/:demoName/apps/:appId"
                  element={<AppInstanceDetails />}
                />
                <Route
                  path="/demo/:demoName/resources"
                  element={<DemoResources />}
                />
                <Route
                  path="/demo/:demoName/resources/:resourceId"
                  element={<ResourceInstanceDetails />}
                />
                <Route path="/app" exact element={<CreateApplication />} />
                <Route path="/app/:appId" element={<ApplicationDetails />} />
                <Route path="/resource" exact element={<CreateResource />} />
                <Route
                  path="/resource/:resourceId"
                  element={<ResourceDetails />}
                />
                <Route
                  path="/library/:appId/create"
                  element={<LibraryDemonstrationCreate />}
                />
                <Route path="/template" element={<TemplateDeploy />} />
                <Route
                  path="/template/:template"
                  element={<TemplateDeploy />}
                />
                <Route path="/vm" exact element={<Heropa />} />
              </Routes>
            </DemoContextProvider>
          </OpportunityContextProvider>
        </UserPreferenceContextProvider>
      </ThemeProvider>
    );
  } else {
    return loginWithRedirect({ appState: { targetUrl: window.location } });
  }
}

export default App;
